import VDateTable from 'vuetify/es5/components/VDatePicker/VDatePickerDateTable';
import pad from 'vuetify/lib/components/VDatePicker/util/pad';
import isDateAllowed from 'vuetify/src/components/VDatePicker/util/isDateAllowed';

export default {
  extends: VDateTable,
  props: {
    schedules: {
      type: [Array, Object, Function],
      default: function _default() {
        return null;
      }
    },
    dayColor: {
      type: [String, Function, Object],
      default: 'accent'
    }
  },
  methods: {
    genEvent(date) {
      let eventColor = void 0;
      if (typeof this.eventColor === 'string') {
        eventColor = this.eventColor;
      } else if (typeof this.eventColor === 'function') {
        eventColor = this.eventColor(date);
      } else {
        eventColor = this.eventColor[date];
      }

      return this.$createElement('div', this.setBackgroundColor(eventColor || this.color || 'accent', {
        staticClass: 'v-date-picker-table__event'
      }));
    },
    genTBody() {
      try {
        const children = [];
        const daysInMonth = new Date(this.displayedYear, this.displayedMonth + 1, 0).getDate();
        let rows = [];
        let day = this.weekDaysBeforeFirstDayOfTheMonth();
        while (day--) {
          rows.push(this.$createElement('td'));
        } for (day = 1; day <= daysInMonth; day++) {
          const date = this.displayedYear + '-' + pad(this.displayedMonth + 1) + '-' + pad(day);
          rows.push(this.$createElement('td', [this.genButton(date, true), this.isEvent && this.isEvent(date) ? this.genEvent(date) : null]));
          if (rows.length % 7 === 0) {
            children.push(this.genTR(rows));
            rows = [];
          }
        }
        if (rows.length) {
          children.push(this.genTR(rows));
        }
        return this.$createElement('tbody', children);
      } catch (error) {
        console.log('error in genTbody', error)
      }
    },
    genButton(value, isFloating) {
      let dayColor;
      if (typeof this.dayColor === 'string') {
        dayColor = this.dayColor;
      } else if (typeof this.dayColor === 'function') {
        dayColor = this.dayColor(value);
      } else {
        dayColor = this.dayColor[value];
      }
      const isAllowed = isDateAllowed(value, this.min, this.max, this.allowedDates);
      const isSelected = value === this.value || (Array.isArray(this.value) && this.value.indexOf(value) !== -1);
      const isCurrent = value === this.current;
      const setColor = isSelected ? this.setBackgroundColor : this.setTextColor;
      const color = (isSelected || isCurrent) && (dayColor || 'accent');

      return this.$createElement('button', setColor(color, {
        staticClass: 'v-btn',
        'class': this.genButtonClasses(isAllowed, isFloating, isSelected, isCurrent),
        attrs: {
          type: 'button'
        },
        domProps: {
          disabled: !isAllowed,
          innerHTML: `<div class="v-btn__content">${this.formatter(value)}</div>`
        },
        on: (this.disabled || !isAllowed) ? {} : {
          click: () => this.$emit('input', value)
        }
      }))
    },
  }
}