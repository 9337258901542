import VDatePicker from 'vuetify/es5/components/VDatePicker/VDatePicker';
import VDatePickerTable from './VDatePickerTable';
import pad from "vuetify/lib/components/VDatePicker/util/pad";

export default {
  extends: VDatePicker,
  props: {
    schedules: {
      type: [Array, Object, Function],
      default: function _default() {
        return null;
      }
    },
    dayColor: {
      type: [String, Function, Object],
      default: 'warning'
    },
  },
  methods: {
    dateClick(value) {
      this.inputYear = parseInt(value.split('-')[0], 10);
      this.inputMonth = parseInt(value.split('-')[1], 10) - 1;
      this.inputDay = parseInt(value.split('-')[2], 10);
      this.emitInput(this.inputDate);
      this.$emit('clickedDate', value);
    },
    genDateTable() {
      const _this6 = this;
      return this.$createElement(VDatePickerTable, {
        props: {
          schedules: this.schedules,
          dayColor: this.dayColor,
          allowedDates: this.allowedDates,
          color: this.color,
          current: this.current,
          dark: this.dark,
          disabled: this.readonly,
          events: this.events,
          eventColor: this.eventColor,
          firstDayOfWeek: this.firstDayOfWeek,
          format: this.dayFormat,
          light: this.light,
          locale: this.locale,
          min: this.min,
          max: this.max,
          tableDate: this.tableYear + '-' + pad(this.tableMonth + 1),
          scrollable: this.scrollable,
          value: this.value,
          weekdayFormat: this.weekdayFormat
        },
        ref: 'table',
        on: {
          input: this.dateClick,
          tableDate: function tableDate(value) {
            return _this6.tableDate = value;
          }
        }
      });
    }
  }
}