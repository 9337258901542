

























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Prop } from 'vue-property-decorator';

@Component({})

export default class extends Vue {
    @Global.State('lang') lang;

    @Prop() days_in_week;

    menu = false;
}

